(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/select-participants.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/select-participants.js');
"use strict";


const selectAllParticipants = state => state.Participants;
const selectParticipants = (state, participantIds) => participantIds ? participantIds.map(participantId => state.Participants[participantId]) : [];
const selectParticipant = (state, participantId) => state.Participants[participantId];
const selectCountry = (state, countryId) => state.Countries[countryId];
setGlobal('svs.components.sportinfo.matchInfoRedux.selectors', {
  selectAllParticipants,
  selectParticipants,
  selectParticipant,
  selectCountry
});

 })(window);