(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/select-bet-event.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/select-bet-event.js');
"use strict";


const selectBetEvents = state => state.BetEvents;
const selectBetEventsByDraw = (state, productId, drawNumber) => {
  if (!productId || !drawNumber) {
    return {};
  }
  const drawId = "".concat(productId, "_").concat(drawNumber);
  const betEvents = {};
  for (const betEvent in state.BetEvents) {
    if (betEvent.startsWith(drawId)) {
      betEvents[betEvent] = state.BetEvents[betEvent];
    }
  }
  return betEvents;
};
const selectBetEvent = (state, betEventKey) => selectBetEvents(state) ? selectBetEvents(state)[betEventKey] : null;
setGlobal('svs.components.sportinfo.matchInfoRedux.selectors', {
  selectBetEvent,
  selectBetEvents,
  selectBetEventsByDraw
});

 })(window);