(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/normalize-event.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/normalize-event.js');
"use strict";

const _excluded = ["country"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }

let normalizeProviders;
let resolveProductId;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  resolveProductId = trinidad.components.require('utils', 'products').resolveProductId;
  normalizeProviders = require('./normalize-providers.es6');
} else {
  resolveProductId = svs.utils.products.resolveProductId;
  normalizeProviders = svs.components.sport.matchInfoRedux.normalizeProviders;
}
const normalizeEvent = (sportEvent, distributions) => {
  const {
    participants,
    league: realLeague,
    venue,
    startTime: matchStart,
    status,
    statusTime,
    sport: realSport,
    providerIds: sportEventProviderIds
  } = sportEvent;
  const parsedParticipants = participants.map(_ref => {
    let {
      sportEventParticipantId: id,
      shortName,
      mediumName,
      name
    } = _ref;
    return {
      id,
      mediumName,
      name,
      shortName
    };
  });
  const [homeTeam, awayTeam] = parsedParticipants;
  const _ref2 = realLeague || {},
    {
      country: realCountry
    } = _ref2,
    league = _objectWithoutProperties(_ref2, _excluded);
  const sport = {
    name: realSport.name,
    type: realSport.sportType
  };
  const country = _objectSpread(_objectSpread({}, realCountry), {}, {
    id: realCountry.countryId
  });
  const parsedLeague = {
    id: league.leagueId,
    name: league.name
  };
  const match = {
    arena: (venue === null || venue === void 0 ? void 0 : venue.name) || null,
    distributions,
    matchStart,
    sportEventStatus: status,
    statusTime
  };
  const parsedDistributions = (distributions !== null && distributions !== void 0 ? distributions : []).reduce((acc, distribution) => _objectSpread(_objectSpread({}, acc), {}, {
    [resolveProductId(distribution.productName)]: distribution
  }), {});
  const providerIds = normalizeProviders(sportEventProviderIds);
  return {
    homeTeam,
    awayTeam,
    league: parsedLeague,
    distributions: parsedDistributions,
    match,
    country,
    sport,
    providerIds
  };
};
if (svs.isServer) {
  module.exports = normalizeEvent;
} else {
  setGlobal('svs.components.sportinfo.matchInfoRedux.normalizeEvent', normalizeEvent);
}

 })(window);