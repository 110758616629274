(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/select-league.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/select-league.js');
"use strict";

const {
  selectSportEvent
} = svs.components.sportinfo.matchInfoRedux.selectors;
const selectLeague = (state, leagueId) => state.Leagues[leagueId];
const selectLeagues = state => state.Leagues;
const selectLeagueFromSportEvent = (state, matchId) => selectSportEvent(state, matchId) ? state.Leagues[selectSportEvent(state, matchId).leagueId] : null;
setGlobal('svs.components.sportinfo.matchInfoRedux.selectors', {
  selectLeague,
  selectLeagues,
  selectLeagueFromSportEvent
});

 })(window);