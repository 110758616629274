(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/generate-participants-mw.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/generate-participants-mw.js');
"use strict";

const {
  isAnyOf,
  isAsyncThunkAction,
  isFulfilled
} = RTK;
const {
  getLogger
} = svs.core.log;
const {
  selectSportEvent
} = svs.components.sportinfo.matchInfoRedux.selectors;


const logger = getLogger('tipsen:store:mw:participants');
const appendParticipantsMeta = (matchId, state, action) => {
  if (matchId) {
    const sportEvent = selectSportEvent(state, matchId);
    if (sportEvent) {
      if (!action.meta) {
        action.meta = {};
      }
      action.meta.participants = sportEvent.participants;
    }
  }
};
const generateParticipantsMw = intrestingActions => {
  const intrestingActionsFields = intrestingActions.reduce((acc, curr) => {
    if (curr.action.type) {
      acc.push(curr.action);
    }
    return acc;
  }, []);
  const hasIntrestingActions = intrestingActionsFields.length > 0;
  const isIntrestingAction = hasIntrestingActions ? isAnyOf(...intrestingActionsFields) : () => false;
  const isFulfilledAction = hasIntrestingActions ? action => {
    if (isAsyncThunkAction(action)) {
      return isFulfilled(action);
    }
    return true;
  } : () => false;
  return _ref => {
    let {
      getState
    } = _ref;
    return next => action => {
      try {
        const isIntresting = isIntrestingAction(action);
        const isFulfilled = isFulfilledAction(action);
        if (isIntresting && isFulfilled) {
          const intrestingActionModel = intrestingActions.find(info => info.action.type === action.type);
          const {
            getMatchIdFn
          } = intrestingActionModel;
          appendParticipantsMeta(getMatchIdFn(action), getState(), action);
        }
      } catch (err) {
        logger.warn('generateParticipantsMw', err);
      } finally {
        next(action);
      }
    };
  };
};
setGlobal('svs.components.sportinfo.matchInfoRedux.generateParticipantsMw', generateParticipantsMw);

 })(window);