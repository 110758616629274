(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/match-info-actions.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/match-info-actions.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  jupiter
} = svs.core;
const {
  createAsyncThunk,
  createAction
} = RTK;
const {
  normalizeEvent
} = svs.components.sportinfo.matchInfoRedux;
const {
  EventTypes,
  OutcomeToAlphabeticStrings,
  EventTypeDistributionsFallback
} = svs.components.sportinfo.common.constants;
const logger = svs.core.log.getLogger('match-info:match-info-actions');
const normalize1X2OddsItems = eventType => {
  if (eventType.length === 0) {
    return null;
  }
  return eventType.reduce((acc, outcome) => {
    acc[OutcomeToAlphabeticStrings[outcome.outcome]] = outcome.value;
    return acc;
  }, {});
};
const normalizeOddsResponse = oddsResponse => {
  let odds = oddsResponse.eventTypes.map(eventType => {
    if (eventType.eventType === EventTypes.EvenType1X2) {
      return {
        eventSubType: eventType.eventSubType,
        eventTypeId: eventType.eventTypeId,
        eventType: eventType.eventType,
        odds: normalize1X2OddsItems(eventType.odds),
        startOdds: normalize1X2OddsItems(eventType.startOdds),
        favourite: normalize1X2OddsItems(eventType.favorite),
        distribution: eventType.distribution.map(distribution => _objectSpread(_objectSpread({}, normalize1X2OddsItems(distribution.distribution)), {}, {
          drawNumber: distribution.drawNumber || EventTypeDistributionsFallback,
          productName: distribution.productId
        }))
      };
    }
    logger.debug("Trying to parse odds for unsupported event type: ".concat(eventType.eventType));
    return false;
  }).filter(Boolean);
  if (!odds.length) {
    odds = [{
      eventSubType: EventTypes.EvenType1X2,
      eventType: null,
      odds: null,
      startOdds: null,
      favourite: null,
      distribution: null
    }];
  }
  return odds;
};
const fetchMatchInfoService = matchId => new Promise((resolve, reject) => {
  const paths = ["/sport/1/sportevent/".concat(matchId), "/sport/1/sportevent/".concat(matchId, "/odds")];
  jupiter.get(paths, data => {
    const [eventResponse, oddsResponse] = data.responses;
    const odds = normalizeOddsResponse(oddsResponse);
    resolve([eventResponse.error ? eventResponse : normalizeEvent(eventResponse.sportEvent, odds[0].distribution), _objectSpread(_objectSpread({}, oddsResponse), {}, {
      oddsInfo: odds[0]
    })]);
  }, error => {
    reject(error);
  });
});
const fetchMatchDataService = matchId => new Promise((resolve, reject) => {
  const path = "/sport/1/sportevent/".concat(matchId);
  jupiter.get(path, data => {
    resolve(data);
  }, error => {
    reject(error);
  });
});
const fetchMatchInfo = createAsyncThunk('fetchMatchInfo', matchId => fetchMatchInfoService(matchId));
const fetchMatchData = createAsyncThunk('fetchMatchData', matchId => fetchMatchDataService(matchId));
const updateMatchOdds = createAction('updateMatchOdds', (matchId, oddsResponse) => ({
  payload: {
    matchId,
    odds: _objectSpread(_objectSpread({}, oddsResponse), {}, {
      oddsInfo: normalizeOddsResponse(oddsResponse)[0]
    })
  }
}));
setGlobal('svs.components.sportinfo.matchInfoRedux.actions', {
  fetchMatchInfo,
  fetchMatchData,
  updateMatchOdds
});

 })(window);