(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/event-type-statistic-reducer.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/event-type-statistic-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  createReducer,
  isAnyOf
} = RTK;
const {
  fetchMatchInfo,
  updateMatchOdds
} = svs.components.sportinfo.matchInfoRedux.actions;
const {
  loadOpenedDrawsByEngine,
  loadDrawByProduct,
  updateDraw
} = svs.components.sport.drawsRedux.actions;
const {
  EventTypeField,
  EventTypeDistributionsFallback
} = svs.components.sportinfo.common.constants;
const {
  createEventStatisticsKey,
  normalizeEventTypeField,
  normalizeOddsItem
} = svs.components.sportinfo.common.helpers;
const normalizeDistributionItem = (distribution, eventTypeId) => distribution.reduce((acc, curr) => {
  if (!acc[curr.productId]) {
    acc[curr.productId] = {};
  }
  if (!acc[curr.productId][curr.drawNumber]) {
    acc[curr.productId][curr.drawNumber] = {};
  }
  acc[curr.productId] = _objectSpread(_objectSpread({}, acc[curr.productId]), {}, {
    [curr.drawNumber]: _objectSpread(_objectSpread({}, acc[curr.productId][curr.drawNumber]), normalizeOddsItem(curr.distribution, eventTypeId))
  });
  return acc;
}, []);
const eventTypeStatisticReducer = createReducer({}, builder => {
  builder.addCase(fetchMatchInfo.fulfilled, (state, action) => {
    const matchId = action.meta.arg;
    const {
      participants,
      periodNumber
    } = action.meta || {};
    const [, odds] = action.payload;
    if (!odds.error) {
      const {
        eventTypes
      } = odds;
      eventTypes.forEach(_ref => {
        let {
          eventTypeId,
          odds,
          startOdds,
          favorite,
          distribution
        } = _ref;
        const eventStatisticsKey = createEventStatisticsKey({
          matchId,
          eventTypeId,
          participantIds: participants,
          periodNumber
        });
        if (odds.length > 0) {
          const newOdds = normalizeEventTypeField(eventTypeId, EventTypeField.Odds, normalizeOddsItem(odds, eventTypeId));
          state[eventStatisticsKey] = _objectSpread(_objectSpread({}, state[eventStatisticsKey]), newOdds);
        }
        if (startOdds.length > 0) {
          const newOdds = normalizeEventTypeField(eventTypeId, EventTypeField.StartOdds, normalizeOddsItem(startOdds, eventTypeId));
          state[eventStatisticsKey] = _objectSpread(_objectSpread({}, state[eventStatisticsKey]), newOdds);
        }
        if (favorite.length > 0) {
          const newOdds = normalizeEventTypeField(eventTypeId, EventTypeField.Favourites, normalizeOddsItem(favorite, eventTypeId));
          state[eventStatisticsKey] = _objectSpread(_objectSpread({}, state[eventStatisticsKey]), newOdds);
        }
        if (distribution.length > 0) {
          var _state$eventStatistic;
          const normalizedDistribution = normalizeDistributionItem(distribution, eventTypeId);
          const newDistributionState = {
            [EventTypeField.Distributions]: _objectSpread({}, (state === null || state === void 0 || (_state$eventStatistic = state[eventStatisticsKey]) === null || _state$eventStatistic === void 0 ? void 0 : _state$eventStatistic[EventTypeField.Distributions]) || {})
          };
          const productIds = Object.keys(normalizedDistribution);
          productIds.forEach(productId => {
            var _state$eventStatistic2;
            const productIdState = ((_state$eventStatistic2 = state[eventStatisticsKey]) === null || _state$eventStatistic2 === void 0 || (_state$eventStatistic2 = _state$eventStatistic2[EventTypeField.Distributions]) === null || _state$eventStatistic2 === void 0 ? void 0 : _state$eventStatistic2[productId]) || {};
            const drawNumbers = Object.keys(normalizedDistribution[productId]);
            drawNumbers.forEach(drawNumber => {
              const parsedDraw = normalizeEventTypeField(eventTypeId, EventTypeField.Distributions, {
                [productId]: normalizedDistribution[productId][drawNumber]
              }, drawNumber);
              productIdState[drawNumber] = _objectSpread(_objectSpread({}, productIdState[drawNumber] || {}), parsedDraw[EventTypeField.Distributions][productId][drawNumber]);
              productIdState[EventTypeDistributionsFallback] = _objectSpread({}, parsedDraw[EventTypeField.Distributions][productId][drawNumber]);
            });
            newDistributionState[EventTypeField.Distributions][productId] = _objectSpread(_objectSpread({}, newDistributionState[EventTypeField.Distributions][productId]), productIdState);
          });
          state[eventStatisticsKey] = _objectSpread(_objectSpread({}, state[eventStatisticsKey]), newDistributionState);
        }
      });
    }
  }).addCase(updateMatchOdds, (state, action) => {
    const {
      matchId,
      odds
    } = action.payload;
    const {
      participants,
      periodNumber
    } = action.meta || {};
    if (!odds || odds.error) {
      return;
    }
    const {
      eventTypes
    } = odds;
    eventTypes.forEach(_ref2 => {
      let {
        eventTypeId,
        odds
      } = _ref2;
      if (odds.length > 0) {
        const eventTypeStatId = createEventStatisticsKey({
          matchId,
          eventTypeId,
          participantIds: participants,
          periodNumber
        });
        const newOdds = normalizeEventTypeField(eventTypeId, EventTypeField.Odds, normalizeOddsItem(odds, eventTypeId));
        const _eventTypeStatId = state[eventTypeStatId] || {};
        const _eventTypeStatIdOdds = _eventTypeStatId[EventTypeField.Odds] || {};
        state[eventTypeStatId] = _objectSpread(_objectSpread(_objectSpread({}, _eventTypeStatId), newOdds), {}, {
          [EventTypeField.Odds]: _objectSpread(_objectSpread({}, _eventTypeStatIdOdds), newOdds[EventTypeField.Odds])
        });
      }
    });
  }).addCase(loadOpenedDrawsByEngine.fulfilled, (state, action) => {
    Object.assign(state, action.payload.EventTypeStatistic);
  }).addMatcher(isAnyOf(loadDrawByProduct.fulfilled, updateDraw), (state, action) => {
    const newEventTypeStatistics = action.payload.EventTypeStatistic || action.payload.draws.EventTypeStatistic || {};
    const eventStatisticsKeys = Object.keys(newEventTypeStatistics);
    eventStatisticsKeys.forEach(eventStatisticsKey => {
      if (!state[eventStatisticsKey]) {
        state[eventStatisticsKey] = newEventTypeStatistics[eventStatisticsKey];
      } else {
        for (const eventStatisticsType in newEventTypeStatistics[eventStatisticsKey]) {
          if (eventStatisticsType !== EventTypeField.Distributions) {
            state[eventStatisticsKey][eventStatisticsType] = newEventTypeStatistics[eventStatisticsKey][eventStatisticsType];
          }
        }

        const distributionKeys = newEventTypeStatistics[eventStatisticsKey].distributions ? Object.keys(newEventTypeStatistics[eventStatisticsKey].distributions) : [];
        distributionKeys.forEach(distributionKey => {
          if (state[eventStatisticsKey].distributions[distributionKey]) {
            state[eventStatisticsKey].distributions[distributionKey] = _objectSpread(_objectSpread({}, state[eventStatisticsKey].distributions[distributionKey]), newEventTypeStatistics[eventStatisticsKey].distributions[distributionKey]);
          } else {
            state[eventStatisticsKey].distributions[distributionKey] = newEventTypeStatistics[eventStatisticsKey].distributions[distributionKey];
          }
        });
      }
    });
  });
});
setGlobal('svs.components.sportinfo.matchInfoRedux.eventTypeStatisticReducer', eventTypeStatisticReducer);

 })(window);