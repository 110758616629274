(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/select-event-type-statistic.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/select-event-type-statistic.js');
"use strict";


const {
  createSelector
} = RTK;
const emptyArr = [];
const selectEventStatistic = (state, eventStatisticId) => eventStatisticId ? state.EventTypeStatistic[eventStatisticId] : null;
const selectEventStatisticByType = (state, eventStatisticId, eventTypeField) => {
  var _selectEventStatistic;
  return ((_selectEventStatistic = selectEventStatistic(state, eventStatisticId)) === null || _selectEventStatistic === void 0 ? void 0 : _selectEventStatistic[eventTypeField]) || null;
};
const selectEventStatisticCurrentValueByType = (state, eventStatisticId, eventTypeField) => {
  var _selectEventStatistic2;
  return ((_selectEventStatistic2 = selectEventStatistic(state, eventStatisticId)) === null || _selectEventStatistic2 === void 0 || (_selectEventStatistic2 = _selectEventStatistic2[eventTypeField]) === null || _selectEventStatistic2 === void 0 || (_selectEventStatistic2 = _selectEventStatistic2.current) === null || _selectEventStatistic2 === void 0 ? void 0 : _selectEventStatistic2.value) || emptyArr;
};
const selectEventStatisticCurrentValueByDrawAndType = (productId, drawNumber, eventStatisticId, eventTypeField) => createSelector(state => {
  var _state$EventTypeStati;
  return (_state$EventTypeStati = state.EventTypeStatistic[eventStatisticId]) === null || _state$EventTypeStati === void 0 || (_state$EventTypeStati = _state$EventTypeStati[eventTypeField]) === null || _state$EventTypeStati === void 0 || (_state$EventTypeStati = _state$EventTypeStati[productId]) === null || _state$EventTypeStati === void 0 ? void 0 : _state$EventTypeStati[drawNumber];
}, eventTypeStatistic => {
  var _eventTypeStatistic$c;
  return (eventTypeStatistic === null || eventTypeStatistic === void 0 || (_eventTypeStatistic$c = eventTypeStatistic.current) === null || _eventTypeStatistic$c === void 0 ? void 0 : _eventTypeStatistic$c.value) || emptyArr;
});
setGlobal('svs.components.sportinfo.matchInfoRedux.selectors', {
  selectEventStatistic,
  selectEventStatisticByType,
  selectEventStatisticCurrentValueByDrawAndType,
  selectEventStatisticCurrentValueByType
});

 })(window);