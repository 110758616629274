(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/select-sport-event.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/select-sport-event.js');
"use strict";


const {
  ReducerState
} = svs.components.sport.tipsenShared;
const {
  createSelector
} = RTK;
const selectSportEvents = state => state.SportEvents;
const selectSportEvent = (state, matchId) => selectSportEvents(state) ? selectSportEvents(state)[matchId] : null;
const selectSportEventsByIds = (state, matchIds) => matchIds ? matchIds.map(matchId => selectSportEvents(state)[matchId]) : [];
const selectSportEventOddsIsDone = (state, matchId) => {
  var _selectSportEvent;
  return ((_selectSportEvent = selectSportEvent(state, matchId)) === null || _selectSportEvent === void 0 ? void 0 : _selectSportEvent.oddsState) === ReducerState.Done;
};
const selectSportEventOddsIsRejected = (state, matchId) => {
  var _selectSportEvent2;
  return ((_selectSportEvent2 = selectSportEvent(state, matchId)) === null || _selectSportEvent2 === void 0 ? void 0 : _selectSportEvent2.oddsState) === ReducerState.Rejected;
};
const selectSportEventOddsShouldShowSkeleton = (state, matchId) => !selectSportEventOddsIsDone(state, matchId) && !selectSportEventOddsIsRejected(state, matchId);
const selectSportEventIsDone = (state, matchId) => {
  var _selectSportEvent3;
  return ((_selectSportEvent3 = selectSportEvent(state, matchId)) === null || _selectSportEvent3 === void 0 ? void 0 : _selectSportEvent3.eventState) === ReducerState.Done;
};
const selectSportEventIsRejected = (state, matchId) => {
  var _selectSportEvent4;
  return ((_selectSportEvent4 = selectSportEvent(state, matchId)) === null || _selectSportEvent4 === void 0 ? void 0 : _selectSportEvent4.eventState) === ReducerState.Rejected;
};
const selectSportEventShouldShowSkeleton = (state, matchId) => !selectSportEventIsDone(state, matchId) && !selectSportEventIsRejected(state, matchId);
const selectSportEventDataIsDone = (state, matchId) => {
  var _selectSportEvent5;
  return ((_selectSportEvent5 = selectSportEvent(state, matchId)) === null || _selectSportEvent5 === void 0 ? void 0 : _selectSportEvent5.eventState) === ReducerState.Done;
};
const selectSportEventDataIsRejected = (state, matchId) => {
  var _selectSportEvent6;
  return ((_selectSportEvent6 = selectSportEvent(state, matchId)) === null || _selectSportEvent6 === void 0 ? void 0 : _selectSportEvent6.eventState) === ReducerState.Rejected;
};
const selectSportEventDataShouldShowSkeleton = (state, matchId) => !selectSportEventDataIsDone(state, matchId) && !selectSportEventDataIsRejected(state, matchId);
const selectExternalId = createSelector(selectSportEvent, sportEvent => sportEvent === null || sportEvent === void 0 ? void 0 : sportEvent.externalId, {
  memoizeOptions: {
    resultEqualityCheck: function () {
      let previousExternalId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let currentExternalId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const k1 = Object.keys(previousExternalId).sort().join(',');
      const k2 = Object.keys(currentExternalId).sort().join(',');
      const v1 = Object.values(previousExternalId).sort().join(',');
      const v2 = Object.values(currentExternalId).sort().join(',');
      return k1 === k2 && v1 === v2;
    }
  }
});
setGlobal('svs.components.sportinfo.matchInfoRedux.selectors', {
  selectSportEvents,
  selectSportEvent,
  selectSportEventsByIds,
  selectSportEventOddsIsDone,
  selectSportEventOddsIsRejected,
  selectSportEventOddsShouldShowSkeleton,
  selectSportEventIsDone,
  selectSportEventIsRejected,
  selectSportEventShouldShowSkeleton,
  selectSportEventDataIsDone,
  selectSportEventDataIsRejected,
  selectSportEventDataShouldShowSkeleton,
  selectExternalId
});

 })(window);