(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/normalize-providers.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/normalize-providers.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

let ExternalIdProviders;
if (svs.isServer) {
  ExternalIdProviders = require('../../common/assets/javascripts/constants.es6').ExternalIdProviders;
} else {
  ExternalIdProviders = svs.components.sportinfo.common.constants.ExternalIdProviders;
}
const normalizeProviders = providerIds => {
  if (!providerIds || !Array.isArray(providerIds)) {
    return {};
  }
  const normalizedProviders = providerIds.filter(_ref => {
    let {
      provider
    } = _ref;
    return ExternalIdProviders[provider];
  }).reduce((acc, _ref2) => {
    let {
      provider,
      id
    } = _ref2;
    return _objectSpread(_objectSpread({}, acc), {}, {
      [ExternalIdProviders[provider]]: id
    });
  }, {});
  return normalizedProviders;
};
if (svs.isServer) {
  module.exports = normalizeProviders;
} else {
  setGlobal('svs.components.sport.matchInfoRedux.normalizeProviders', normalizeProviders);
}

 })(window);